<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <template>
      <v-card class="elevation-12">
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title>Block Student for Offering</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-card-text>
          <v-form>
             <v-row>
               <v-col cols="3" sm="3" md="3">
                <v-text-field
                  placeholder="Enter PRN Number"
                  clearable
                  required
                  v-model="prnno"
                ></v-text-field>
               </v-col>
               <v-col cols="3" sm="3" md="3">
                 <v-select :items="offeringlist" placeholder="Select Company Offering"
                              v-model="offering" item-text="offering" item-value="id"></v-select>
               </v-col>
               <v-col cols="3" sm="3" md="3">    
                 <center><v-btn class="mr-4" color="primary" @click="getstudentdetails()">Get Details</v-btn></center>
               </v-col>
               <v-col cols="3" sm="3" md="3">
                     <v-btn color="blue" dark @click="exceldialog = !exceldialog"
                          >Excel Import</v-btn
                        >
                      <v-dialog width="350" persistent v-model="exceldialog">
                        <v-card>
                          <v-card-title class="c-title">Excel import</v-card-title>
                          <v-card-text
                            ><br />
                            <v-row justify="end">
                              <a :href="url">
                                <v-icon large color="blue" class="icon-quick" title="Download">
                                  mdi-file-excel
                                </v-icon>
                              </a>
                            </v-row>
                            <v-file-input
                              v-model="excelfile"
                              label="Import Excel"
                            ></v-file-input>

                              <v-autocomplete  :items="offeringlist" placeholder="Select Company Offering"
                              v-model="newoffering" item-text="offering" item-value="id"></v-autocomplete>
                                  

                          </v-card-text>
                          <v-card-actions>
                            <v-row class="pr-5" justify="end">
                              <v-btn @click="exceldialog = false" text>cancel</v-btn>
                              <v-btn @click="uploadexcel()" dark color="blue">Upload</v-btn>
                            </v-row>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
               </v-col>

             </v-row>     
             <v-row>
                  <v-col cols="4" sm="4" md="4">
                       <v-text-field v-model="numofdays" @input="setdate(numofdays)" label="Number of Days" dense
                          ></v-text-field>
                  </v-col> 
                  <v-col cols="1" sm="1" md="1"></v-col>
                  <v-col cols="4" sm="4" md="4">
                       <input
                        type="date"
                        v-model="afterdate"  
                      />    
                  </v-col>   
             </v-row>     
          </v-form>
        </v-card-text>
      </v-card>
<br>
  <v-dialog v-model="errordialog" max-width="500px">
                         <v-data-table  id="exceltable"  :headers="headers1" :items="errorarray" class="elevation-1" :search="search">
                        <template v-slot:top>
                            <v-toolbar flat color="white">                              
                                <v-text-field v-model="search" append-icon="search" label="Search " single-line hide-details></v-text-field>
                                <v-spacer></v-spacer>
                                <v-btn  style="margin-right:50px" v-if="errorarray.length != 0"  @click="exportexcel()" color="success" dark class="mb-2">
                                    <v-icon dark>mdi-file-excel</v-icon>
                                </v-btn>
                            </v-toolbar>
                        </template>                       
                    </v-data-table>
                  </v-dialog>
       <v-card   class="elevation-12" v-if="name!=''">
      <v-row style="margin-left:10px"><v-col cols="12" sm="2"> {{grno}}</v-col>
    <v-col cols="12" sm="3"> {{name}}</v-col>
    <v-col cols="12" sm="4"> {{email}}</v-col>
    <v-col cols="12" sm="2"> {{mobile}}</v-col>
      </v-row><br>
      <v-row style="margin-left:10px">
          <v-col class="sm-md-7">
                  <v-text-field 
                    v-model="reason"
                    placeholder="Enter Reason to block student"
                    clearable
                  ></v-text-field>
                  </v-col>
          </v-col>
         

                  <v-dialog v-model="dialog" max-width="500px">
                        <v-card>
                            <v-form v-model="valid">
                                <v-card-title>
                                    <v-row justify="space-between" > 
                                    <span class="headline">Add Reason</span>
                                    <v-btn  icon dark @click="dialog = false">
                               <v-icon color="black">mdi-close</v-icon>
                               </v-btn>
                          </v-row>
                                    <!-- <span class="headline">{{ formTitle }}</span> -->
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" sm="12" md="12">
                                                <v-text-field v-model="reasonadd" label="Enter Reason To block Student" :rules="[v => !!v || 'required']">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>

                                    </v-container>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" outlined text @click="close">Cancel</v-btn>
                                    <v-btn color="primary darken-1" dark @click="savereasoninmaster()" :disabled="!valid">Save</v-btn>
                                </v-card-actions>
                            </v-form>
                        </v-card>
                  </v-dialog>

                 

                  <v-col cols="3">    
                       <center><v-btn class="mr-4" color="primary" @click="blockandsavereason()">Block</v-btn></center>
                  </v-col>
      </v-row>
      </v-card>


      <br>
       <v-card v-if="learnerlist.length !=0 " color="grey lighten-4 ">
            <v-data-table  id="exceltable"
                :headers="headers"
                :items="learnerlist"
                class="elevation-1"
                :footer-props="{'items-per-page-options':[50, 100, -1]}"
                :search="search"
                >
                <template v-slot:item.unblock="{ item }">
                        <v-btn class="mr-4" color="primary" @click="unblockstudent(item)">Unblock</v-btn></center>
               </v-col>
                
                </template> 
            </v-data-table>    

      </v-row>
      </v-card>
    </template>
  </div>
</template>
<script>
import axios from "axios";
import {table2excel} from "../jquery.table2excel";
import readXlsxFile from "read-excel-file";
export default {
  data() {
    return {

        prnno: "",
        reason:"",
      name:"",
      email:"",
      program:"",
      value: String,
      message: "",
      numofdays:"",
      snackbar_msg: "",
      learnerlist:[],
      errorarray:[],
      offering:"",
      offeringlist:"",
      errordialog:false,
      resonlist:[],
      newreason:"",
      exceldialog:false,
      color: "",
      afterdate:null,
      dialog:false,
      snackbar: false,
      isplaced:false,
      isintern:false,
      isyeardown:false,
      isgoinforhigherstudies:false,
      unfreezeExamination:false,
      unfreezePersonal:false,
      unfreezeAcademics:false,
      reasonadd:"",
      url:"",
      excelfile:null,
      search:"",


       headers: [
     // { text: "Sr.No", value: "srno" }, 
      { text: "GRNO", value: "grno" },
       { text: "Name", value: "name" }, 
       { text: "Email", value: "email" },
       { text: "Mobile", value: "mobile" },
       { text: "Company Offering", value: "offering" },
       { text: "Reason", value: "reason" },
       { text: "Unblock", value: "unblock" },
    ],
     headers1: [
                {
                    text: "Registration Number",
                    value: "grno",
                },
                {
                    text: "Message",
                    value: "msg",
                },
     ]
    };
    
  },
   mounted() {
    this.init();
  },
  methods: {
      showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
        getstudentdetails(){
            const data = {                 
                   reg_no:this.prnno,
                };
       axios
            .post("/LearnerData/getstudentdetailstoblock",data)
            .then((res) => {
              if (res.data.msg == "200") {
                this.grno=res.data.grno;
                 this.name=res.data.name;
                  this.email=res.data.email;
                   this.mobile=res.data.mobile;
                   this.reasonlist = res.data.reasonlist;
              } 
            })
            .catch((error) => {
              window.console.log(error);
            });
     
    },
      exportexcel() {    
               
        $("#exceltable").table2excel({  
          name: "Worksheet Name",
          filename: "Learner_notfound", //do not include extension
          fileext: ".xls" // file extension
        });        
      },

setdate(days){
  alert("setdate"+days);
    // const d = new Date();
    //  let day = days;
    //  d.setDate(d.getDate() + (day));

  //    var result = new Date();
  //    console.log("result");console.log(result); console.log(days);
  // result.setDate(result.getDate() + this.numofdays);

  //     console.log("after");
  //    console.log(result);
  // this.afterdate = d;

      axios
            .post("/LearnerData/setdate",days)
            .then((res) => {
              if (res.data.msg == "200") {
                //console.log("newdate")
               //console.log(res.data.newdate)
              } 
            })
            .catch((error) => {
              window.console.log(error);
            });


},

     uploadexcel() {
      if (this.excelfile == null) {
        this.showSnackbar("red", "Please select file");
      }
       else {
       var list=[];
      
      readXlsxFile(this.excelfile).then((rows) => {
        //console.log(rows);
        var i;
        for (i = 1; i < rows.length; i++) {
      var temp={};
           temp['PRN']=rows[i][0];
          temp['Reason']=rows[i][1];
        list.push(temp);
        }
        
      var params={"list":list,"offering": this.newoffering};
     //console.log("hii")
      //console.log(params)
        //   var params = {
        //     studentlist: studentlist,
        //     offering: this.newoffering,
        //   };
          axios.post("/LearnerData/blockstudentforofferingexcel", params).then((res) => {
            if (res.data.msg == "200") {
              this.learnerlist=res.data.learnerlist;
              this.errorarray = res.data.errorarray;
              //console.log("errrooorr");
              //console.log(this.errorarray);
              //console.log(this.errorarray.length);
              if(this.errorarray.length != 0){
                //console.log("noooo")
                this.errordialog = true;
              }else{
                  this.showSnackbar("green", "Successfully blocked Students");
              }
              
            } else {
              this.showSnackbar("red", res.data.msg);
            }
          });
        });

        this.exceldialog = false;
      }
    },

     init(){
      //  alert("hi");
       axios
            .post("/LearnerData/getblockedstudentsforoffering")
            .then((res) => {
              if (res.data.msg == "200") {
                this.learnerlist=res.data.learnerlist;
                this.offeringlist = res.data.offeringlist;
                this.url = res.data.url;
                //console.log("hiiiiiiiiiii");
                //console.log(res.data.offeringlist);
              } 
            })
            .catch((error) => {
              window.console.log(error);
            });
     
    },
    savereasoninmaster(){
       const data = {                 
                   reasonadd:this.reasonadd,
                };
       axios
            .post("/LearnerData/savereasoninmaster",data)
            .then((res) => {
              if (res.data.code == "200") {
                this.showSnackbar("green", "Reason Saved Successfully");
                this.reasonlist = res.data.reasonlist;
                this.dialog=false;
              } else {
                 this.showSnackbar("red", res.data.code);
              }
            })
            .catch((error) => {
              window.console.log(error);
            });
        
    },
    blockandsavereason(){
      if(this.reason == ""){
        this.showSnackbar("red", "Please enter Reason");
        return;
      }
      if(this.offering ==""){
        this.showSnackbar("red", "Please company offering");
        return;
      }
        if(confirm("Are you sure, you want to BLOCK this student ?")){
            const data = {                 
                   reg_no:this.prnno,
                   reason:this.reason,
                   offering:this.offering,
                };
       axios
            .post("/LearnerData/blockandsavereasonforoffering",data)
            .then((res) => {
              if (res.data.msg == "200") {
                this.learnerlist=res.data.learnerlist;
                this.showSnackbar("green", "Student Blocked");
              } 
            })
            .catch((error) => {
              window.console.log(error);
            });
     
    }

    },
     unblockstudent(item){
        //console.log(item);
        if(confirm("Are you sure, you want to UNBLOCK this student ?")){
            const data = {                 
                   reg_no:item.grno,
                   offering:item.offering
                };
       axios
            .post("/LearnerData/unblockstudentforoffering",data)
            .then((res) => {
              if (res.data.msg == "200") {
                this.learnerlist=res.data.learnerlist;
                this.showSnackbar("green", "Student Unblocked");
              } 
            })
            .catch((error) => {
              window.console.log(error);
            });
     
    }

    }
  },
};
</script>  
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
</style>